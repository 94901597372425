import { createMuiTheme } from "@material-ui/core/styles";
import {green, amber } from "@material-ui/core/colors";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";

const rawTheme = createMuiTheme({
  palette: {
    primary: {
      xLight: "#737373",
      light: "#484848",
      main: "#212121",
      dark: "#000000"
    },
    secondary: {
      light: "#ffffb0",
      main: amber[200],
      dark: "#ca9b52"
    },
    warning: {
      light: "#ff8a50",
      main: "#ff5131",
      dark: "#c41c00"
    },
    info: {
      light: "#6dbcfc",
      main: "#308cc9",
      dark: "#005f98"
    },
    error: {
      light: red[300],
      main: red[500],
      dark: red[800]
    },
    success: {
      light: green[200],
      main: green[600],
      dark: green[900]
    }
  },
  typography: {
    fontFamily: "'Open Sans','Roboto', 'Roboto Condensed', sans-serif",
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
    fontFamilySecondary: "'Source Serif Pro', sans-serif",
    useNextVariants: true
  }
});

const fontHeader = {
  color: rawTheme.palette.primary.contrastText,
  fontWeight: rawTheme.typography.fontWeightRegular,
  fontFamily: rawTheme.typography.fontFamily,
  textTransform: "cursive"
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      paper: rawTheme.palette.primary.dark,
      default: "#000000",
      placeholder: "#000000"
    }
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 50
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 42
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 36
    },
    h4: {
      ...rawTheme.typography.h4,
      fontSize: 30
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 24,
      fontWeight: rawTheme.typography.fontWeightLight
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 18
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 16
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 14
    }
  }
};

export default theme;
