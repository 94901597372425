import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FileUpload } from "components/Upload";
import { LoginForm } from "components/Login";
import Header from "components/Header";
import { useAuth, useFirebase } from "fbase";
import {
  Container,
  StepConnector,
  withStyles,
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  colors,
  Typography
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import sidebarImage from "assets/img/stars_header.jpg";
import { hexToRgb } from "assets/jss";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100vh",
    background: theme.palette.primary.dark
  },
  stepper: {
    width: "100%",
    color: "#fff",
    background: "inherit",
    padding: "0px"
  },
  label: {
    color: "#ddd"
  }
}));

const ColorlibConnector = withStyles(theme => ({
  alternativeLabel: {
    top: 22
  },
  active: {
    "& $line": {
      backgroundImage: `linear-gradient( 95deg, rgb(${hexToRgb(
        theme.palette.info.dark
      )}) 0%,rgb(${hexToRgb(theme.palette.info.main)}) 90%,rgb(${hexToRgb(
        theme.palette.info.light
      )}) 100%)`}
  },
  completed: {
    "& $line": {
      backgroundImage: `linear-gradient( 95deg, rgb(${hexToRgb(
        theme.palette.success.dark
      )}) 0%,rgb(${hexToRgb(theme.palette.success.main)}) 90%,rgb(${hexToRgb(
        theme.palette.success.light
      )}) 100%)`
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1
  }
}))(StepConnector);

const useColorlibStepIconStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  active: {
    backgroundImage: `linear-gradient( 95deg, rgb(${hexToRgb(
      theme.palette.secondary.dark
    )}) 0%,rgb(${hexToRgb(theme.palette.secondary.main)}) 90%,rgb(${hexToRgb(
      theme.palette.secondary.light
    )}) 100%)`
  },
  completed: {
    backgroundImage: `linear-gradient( 95deg, rgb(${hexToRgb(
      theme.palette.success.dark
    )}) 0%,rgb(${hexToRgb(theme.palette.success.main)}) 90%,rgb(${hexToRgb(
      theme.palette.success.light
    )}) 100%)`
  }
}));

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <PersonIcon />,
    2: <AddIcon />,
    3: <CloudUploadIcon />
  };

  return (
    <div
      className={classNames(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node
};

const steps = ["Logga in", "Välj bilder", "Ladda upp bilder"];

const UploadPage = props => {
  const classes = useStyles();
  const auth = useAuth();
  const firebase = useFirebase();
  const [firstRender, setFirstRender] = useState(true);
  const [fileChosen, setFileChosen] = useState(false);

  useEffect(() => {
    setFirstRender(false); // to avoid Loginform being shown first render
  });

  const getActiveStep = () => {
    if (!auth.uid) {
      return 0;
    } else if (!fileChosen) {
      return 1;
    } else {
      return 2;
    }
  };

  return (
    <div className={classes.root}>
      <Header
        brand="Pyret"
        color="transparent"
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        image={sidebarImage}
      />
      <Container className={classes.container}>
        <Stepper
          activeStep={getActiveStep()}
          alternativeLabel
          connector={<ColorlibConnector />}
          className={classes.stepper}
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel
                className={classes.label}
                StepIconComponent={ColorlibStepIcon}
              >
                <Typography className={classes.label}>{label}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {!firstRender && (
          <div>
            {auth.uid ? (
              <FileUpload firebase={firebase} setFileChosen={setFileChosen} />
            ) : (
              <LoginForm />
            )}
          </div>
        )}
      </Container>
    </div>
  );
};

export default UploadPage;
