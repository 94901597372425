import React from "react";
import "./SlideShowPage.css";

import Slideshow from "components/Slideshow";
import parallaxImage from "assets/img/nature-bg.jpg";
import sidebarImage from "assets/img/stars_header.jpg";
import Header from "components/Header";
import Parallax from "components/Parallax";
import { Container, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Background from "assets/img/nature-bg4.jpg"
const useStyles = makeStyles(theme => ({
    root: {
        background:`url(${Background})`,
        minHeight: "100vh",
        backgroundPosition: "center center",
        backgroundSize: "cover",
    },
    slideShow: {
        alignItems: "center",
        [theme.breakpoints.up("xs")]: {
          padding: theme.spacing(8), 
        },
        [theme.breakpoints.up("lg")]: {
          padding: theme.spacing(15), 
        },
        background: "transparent"
    },
    divider: {
        margin: "20px 20px"
    }
}))
const SlideshowPage = props => {
    const classes = useStyles(props.theme);
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
  return (
    <div className={classes.root}>
      <Header
        brand="Pyret"
        color="transparent"
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        image={sidebarImage}
      />
      <Container className={classes.slideShow}>
        <Slideshow />
      </Container>
    </div>
  );
};
export default SlideshowPage;
