import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import HomePage from "views/Home";
import SlideshowPage from "views/Slideshow";
import ImageFeedPage from "views/Album";
import UploadPage from "views/Upload";
import PetsIcon from '@material-ui/icons/Pets';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


// core components/views for RTL layout
export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const ALBUM = "/album";
export const SLIDES = "/slides";
export const UPLOAD = "/upload";
export const ADMIN = "/admin";

const routes = [
  {
    path: HOME,
    name: "Startsida",
    icon: PetsIcon,
    component: HomePage,
    layout: "/admin"
  },
  {
    path: SLIDES,
    name: "Min bildshow",
    icon: BurstModeIcon,
    component: SlideshowPage,
    layout: "/experience"
  },
  {
    path: ALBUM,
    name: "Mitt album",
    icon: PhotoAlbumIcon,
    component: ImageFeedPage,
    layout: "/courses"
  },
  {
    path: UPLOAD,
    name: "Lägg upp bild",
    icon: CloudUploadIcon,
    component: UploadPage,
    layout: "/courses"
  }
];

export default routes;
