import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";

import * as ROUTES from "routes";
import { useFirebase } from "fbase";

const initState = {
  email: "",
  password: "",
  error: null
};
function LoginFormBase(props) {
  const firebase = useFirebase();
  const [email, setEmail] = useState(initState.email);
  const [password, setPassword] = useState(initState.password);
  const [error, setError] = useState(initState.error);

  const onSubmit = e => {
    const pyretMail = email + "@pyret.com";
    firebase
      .signIn(pyretMail, password)
      .then(authUser => {
        reset();
        props.history.push(ROUTES.UPLOAD);
      })
      .catch(error => {
        setError(error);
      });

    e.preventDefault();
  };

  const reset = () => {
    setEmail(initState.email);
    setPassword(initState.password);
    setError(initState.error);
  };

  return (
    <Grid textAlign="center" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" inverted textAlign="center">
          {/*<Image src="../../login_icon.png" />*/} Logga in på mitt konto
        </Header>
        <Form size="large">
          <Segment stacked>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              color="black"
              placeholder="Mitt namn"
              onChange={e => setEmail(e.target.value)}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Vad jag älskar"
              type="password"
              onChange={e => setPassword(e.target.value)}
            />
            <Button
              color="linkedin"
              fluid
              size="large"
              onClick={onSubmit}
            >
              Login
            </Button>
            {error && <Message>{error.message}</Message>}
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
}

const LoginForm = withRouter(LoginFormBase);

export default LoginForm;
