import React, { useState, useEffect } from "react";
import { useFirebase } from "fbase";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";

import parallaxImage from "assets/img/pyret_lisa_bg.png";
import sidebarImage from "assets/img/stars_header.jpg";
import dentastixImage from "assets/img/dentastix.png";
import Parallax from "components/Parallax";
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Typography,
  Button,
  IconButton,
  Divider,
  Paper,
  useTheme,
  BottomNavigation,
  BottomNavigationAction
} from "@material-ui/core";
import { scrollToId } from "utils";
import Header from "components/Header";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "0em",
    textAlign: "center",
    zIndex: 2000,
    position: "absolute",
    left: 0,
    right: 0,
    background: theme.palette.primary.dark
  },
  quote: {
    padding: "2.5em 1.5em",
    margin: "0 0 10px",
    borderLeft: `2px solid ${theme.palette.primary.light}`
  },
  quoteText: {
    color: "whitesmoke",
    margin: "0 0 30px",
    fontStyle: "italic",
    wordWrap: "wrap"
  },
  quoteName: {
    color: "whitesmoke",
    fontSize: "1.33em"
  },
  card: {
    boxShadow:
      "2px 0px 2px 0px rgba(255, 255, 255, 0.14), 0px 0px 2px 2px rgba(255, 255, 255, 0.2), 0 -2px 2px 2px rgba(255, 255, 255, 0.12)",
    textAlign: "center",
    padding: "10px 15px 0px 15px",
    background: theme.palette.primary.main,
    margin: "0 auto",
    maxWidth: "90%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "30%"
    }
  },
  cardContent: {
    color: "whitesmoke"
  },
  button: {
    background: theme.palette.primary.xLight,
    '&:hover': {
      background: theme.palette.primary.light
    },
  },
  footer: {
    background: theme.palette.primary.main,
    width: "100%",
    bottom: 0
  },
  footerText: {
    fontSize: "1.33em",
    color: theme.palette.primary.contrastText
  },
  link: {
    cursor: "pointer",
    fontSize: "1.66em"
  },
  segment: {
    margin: "0 auto",
    width: "100%",
    padding: "25px 15px",
    background: theme.palette.primary.dark
  },
  media: {
    margin: "0 auto",
    width: "100px"
    //paddingTop: '56.25%', // 16:9
  },
  likes: {
    marginTop: -theme.spacing(1),
    paddingBottom: theme.spacing(2),
    textAlign: "center"
  }
}));
const Homepage = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const firebase = useFirebase();
  const [dentastix, setDentastix] = useState("");
  const [likes, setLikes] = useState("");
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
  useEffect(() => {
    getDentastix();
    getLikes();
  }, []);

  const getDentastix = async () => {
    setDentastix(await firebase.getDentastix());
  };

  const getLikes = async () => {
    setLikes(await firebase.getLikes());
  };

  const addLike = () => {
    setLikes(c => c + 1);
    firebase.addLike();
  };

  const addDentastix = () => {
    setDentastix(c => c + 1);
    firebase.addDentastix();
  };

  return (
    <>
      <Header
        brand="Pyret"
        color="transparent"
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        image={sidebarImage}
      />
      <Parallax size="full" filter image={parallaxImage} />

      <Paper className={classes.root}>
        <Grid container>
          <Grid item className={classes.quote} xs={12} sm={6} id="Quote">
            <Typography variant="h4" className={classes.quoteText}>
              "Ursäkta, men det sitter en bajskorv på din hunds rumpa."
            </Typography>
            <Typography paragraph className={classes.quoteName}>
              Barn, okänd ålder
            </Typography>
          </Grid>
          <Grid item className={classes.quote} xs={12} sm={6}>
            <Card>
              <Typography variant="h4" className={classes.quoteText}>
                "Jävlar vad söt hon är..."
              </Typography>
              <Typography paragraph className={classes.quoteName}>
                David, 26 år
              </Typography>
            </Card>
          </Grid>
          <div className={classes.segment} id="Philosophy">
            <a className={classes.link} onClick={() => scrollToId("Philosophy")}>
              Filosofi
            </a>
            <Card className={classes.card}>
              <CardHeader
                title="Livet handlar om tre saker!"
                className={classes.quoteName}
              ></CardHeader>
              <CardContent className={classes.cardContent}>
                <Typography paragraph className={classes.quoteText}>
                  Varför kan inte folk fatta det? Sluta göra det så himla
                  komplicerat.
                  <br />
                  Sova, äta och bada!
                </Typography>
                <Button
                  variant="contained"
                  onClick={addLike}
                  className={classes.button}
                >
                  <Typography
                    variant="button"
                    style={{ paddingRight: "0.6em" }}
                  >
                    Håll med
                  </Typography>
                  <FavoriteIcon color="secondary" />
                </Button>
              </CardContent>
              <Button disabled className={classes.likes}>
                <Typography
                  variant="h4"
                  style={{ color: "green", paddingRight: "10px" }}
                >
                  {likes}
                </Typography>
                <ThumbUpIcon fontSize="medium" style={{ color: "green" }} />
              </Button>
            </Card>
          </div>

          <Divider spacing={5} />
          <div className={classes.segment} id="Food">
            <a className={classes.link} onClick={() => scrollToId("Food")}>
              Favoritmat
            </a>
            <Card className={classes.card}>
              <CardHeader
                title="Har jag berättat om Dentastix?"
                className={classes.quoteName}
              />
              <CardMedia
                component="img"
                className={classes.media}
                image={dentastixImage}
              />

              <CardContent className={classes.cardContent}>
                <Typography paragraph className={classes.quoteText}>
                  Det är det absolut bästa jag vet.
                  <br/>
                  Det smakar bra, är roligt att tugga
                  och är bra för tänderna.
                </Typography>

                <Button
                  variant="contained"
                  id="Food"
                  onClick={addDentastix}
                  className={classes.button}
                >
                  <Typography
                    variant="button"
                    style={{ paddingRight: "0.6em" }}
                  >
                    Ge mig en
                  </Typography>
                  <FavoriteIcon color="secondary" />
                </Button>
              </CardContent>
              <Button disabled className={classes.likes}>
                <Typography variant="h4" style={{ color: "green", paddingRight: "10px" }}>
                  {dentastix}
                </Typography>
                <ThumbUpIcon fontSize="medium" style={{ color: "green" }} />
              </Button>
            </Card>
          </div>
        </Grid>
        <BottomNavigation
          value={""}
          onChange={(event, newValue) => {
            switch (newValue) {
              case 0: {
                scrollToId("Quote");
                break;
              }
              case 1: {
                scrollToId("Philosophy");
                break;
              }
              case 2: {
                scrollToId("Food");
                break;
              }
              default: {
                break;
              }
            }
          }}
          showLabels
          className={classes.footer}
        >
          <BottomNavigationAction
            label="Citat"
            className={classes.footerText}
            icon={<FormatQuoteIcon color="secondary" />}
          />
          <BottomNavigationAction
            label="Filosofi"
            className={classes.footerText}
            icon={<AcUnitIcon color="secondary" />}
          />
          <BottomNavigationAction
            label="Favoritmat"
            className={classes.footerText}
            icon={<FastfoodIcon color="secondary" />}
          />
        </BottomNavigation>
      </Paper>
    </>
  );
};

export default Homepage;
