import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import * as ROUTES from "routes";

import HomePage from "views/Home";
import ImageFeedPage from "views/Album";
import SlideShowPage from "views/Slideshow";
import UploadPage from "views/Upload";

function App() {
  return (
    <div className="App">
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <Route exact path={ROUTES.LANDING} component={PageShell(HomePage)} />
        {/*<Route path={ROUTES.SIGN_IN} component={LoginPage} />*/}
        <Route path={ROUTES.HOME} component={PageShell(HomePage)} />
        <Route path={ROUTES.ALBUM} component={PageShell(ImageFeedPage)} />
        <Route path={ROUTES.SLIDES} component={PageShell(SlideShowPage)} />
        <Route path={ROUTES.UPLOAD} component={PageShell(UploadPage)} />
      </Router>
    </div>
  );
}

const PageShell = (Page, previous) => {
  return props => (
    <div className="page">
      <ReactCSSTransitionGroup
        transitionAppear={true}
        transitionAppearTimeout={600}
        transitionEnterTimeout={600}
        transitionLeaveTimeout={600}
        transitionName={"SlideIn"} //props.match.path === "/about" ? "SlideIn" : "SlideOut"}
      >
        <Page {...props} />
      </ReactCSSTransitionGroup>
    </div>
  );
};

export default App;
