import exif from "exif-js";

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export function scrollToId(id) {
  const element = document.getElementById(id);
  console.log(element);
  window.scrollTo({
    behavior: element ? "smooth" : "auto",
    top: element ? element.offsetTop + window.innerHeight : 0
  });
}

/* --------------Fix exif orientation problem functions-------------- */
export function readFile(file) {
  return new Promise(resolve => {
    var reader = new FileReader();
    reader.onload = e => resolve(e.target.result);
    reader.readAsDataURL(file);
  });
}

export function createImage(data) {
  return new Promise(resolve => {
    const img = document.createElement("img");
    img.onload = () => resolve(img);
    img.src = data;
  });
}

export function rotate(type, img) {
  return new Promise(resolve => {
    const canvas = document.createElement("canvas");

    exif.getData(img, function() {
      var orientation = exif.getAllTags(this).Orientation;

      if ([5, 6, 7, 8].indexOf(orientation) > -1) {
        canvas.width = img.height;
        canvas.height = img.width;
      } else {
        canvas.width = img.width;
        canvas.height = img.height;
      }

      var ctx = canvas.getContext("2d");

      switch (orientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, img.width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, img.width, img.height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, img.height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, img.height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, img.height, img.width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, img.width);
          break;
        default:
          ctx.transform(1, 0, 0, 1, 0, 0);
      }
      ctx.drawImage(img, 0, 0, img.width, img.height);
      canvas.toBlob(resolve, type);
    });
  });
}

export function resetOrientation(srcBase64, srcOrientation, callback) {
  var img = new Image();

  img.onload = function() {
    var width = img.width,
      height = img.height,
      canvas = document.createElement("canvas"),
      ctx = canvas.getContext("2d");

    // set proper canvas dimensions before transform & export
    if (4 < srcOrientation && srcOrientation < 9) {
      canvas.width = height;
      canvas.height = width;
    } else {
      canvas.width = width;
      canvas.height = height;
    }

    // transform context before drawing image
    switch (srcOrientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, width, 0);
        break;
      case 3:
        ctx.transform(-1, 0, 0, -1, width, height);
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, height);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, height, 0);
        break;
      case 7:
        ctx.transform(0, -1, -1, 0, height, width);
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, width);
        break;
      default:
        break;
    }

    // draw image
    ctx.drawImage(img, 0, 0);

    // export base64
    callback(canvas.toDataURL());
  };

  img.src = srcBase64;

  var originalImage = document.getElementById("image-original"),
    resetImage = document.getElementById("image-reset");

  resetOrientation(originalImage.src, 5, function(resetBase64Image) {
    resetImage.src = resetBase64Image;
  });
}
/* ---------------------------------------------------------------------- */

/*
export function resizeImage(url, width, height, callback) {
  var sourceImage = new Image();

  sourceImage.onload = function() {
    // Create a canvas with the desired dimensions
    var canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    // Scale and draw the source image to the canvas
    canvas.getContext("2d").drawImage(sourceImage, 0, 0, width, height);

    // Convert the canvas to a data URL in PNG format
    callback(canvas.toDataURL());
  };

  sourceImage.src = url;
}

export function getOrientation(file, callback) {
  var reader = new FileReader();
  reader.onload = function(e) {

      var view = new DataView(e.target.result);
      if (view.getUint16(0, false) != 0xFFD8)
      {
          return callback(-2);
      }
      var length = view.byteLength, offset = 2;
      while (offset < length) 
      {
          if (view.getUint16(offset+2, false) <= 8) return callback(-1);
          var marker = view.getUint16(offset, false);
          offset += 2;
          if (marker == 0xFFE1) 
          {
              if (view.getUint32(offset += 2, false) != 0x45786966) 
              {
                  return callback(-1);
              }

              var little = view.getUint16(offset += 6, false) == 0x4949;
              offset += view.getUint32(offset + 4, little);
              var tags = view.getUint16(offset, little);
              offset += 2;
              for (var i = 0; i < tags; i++)
              {
                  if (view.getUint16(offset + (i * 12), little) == 0x0112)
                  {
                      return callback(view.getUint16(offset + (i * 12) + 8, little));
                  }
              }
          }
          else if ((marker & 0xFF00) != 0xFF00)
          {
              break;
          }
          else
          { 
              offset += view.getUint16(offset, false);
          }
      }
      return callback(-1);
  };
  reader.readAsArrayBuffer(file);
}*/
