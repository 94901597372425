import React from "react";
import ImageFeed from "components/ImageFeed";
import sidebarImage from "assets/img/stars_header.jpg";
import Header from "components/Header";
import { Divider, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  root: { minHeight: "100vh", background: theme.palette.primary.dark },
  headerDivider: {
    padding: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(7)
    }
  },
  title: {
    margin: theme.spacing(2),
    color: theme.palette.secondary.dark
  },
  constainer: {}
}));
const ImageFeedPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header
        brand="Pyret"
        color="transparent"
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        image={sidebarImage}
      />
      <Divider className={classes.headerDivider} />
      <Typography variant="h1" className={classes.title}>Album</Typography>
      <Container className={classes.container}>
        <ImageFeed />
      </Container>
    </div>
  );
};

export default ImageFeedPage;
