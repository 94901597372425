import React, { useState, useRef } from "react";
import FileUploader from "react-firebase-file-uploader";
import {
  Grid,
  LinearProgress,
  Divider,
  makeStyles,
  Icon,
  Button,
  Container
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { readFile, createImage, rotate } from "utils";
import { useFirebase } from "fbase";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "1em 0em"
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    padding: 0
  },
  deleteButton: {
    margin: theme.spacing(1),
    lineHeight: "inherit",
    color: theme.palette.getContrastText(red[300]),
    backgroundColor: red[400],
    "&:hover": {
      backgroundColor: red[800]
    }
  },
  saveButton: {
    margin: theme.spacing(1),
    lineHeight: "inherit",
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  uploadButton: {
    margin: theme.spacing(1),
    lineHeight: "inherit",
    color: theme.palette.getContrastText(green[500]),
    background: theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.secondary.dark
    }
  }
}));

const initialState = {
  filenames: [],
  URLs: [],
  isUploading: false,
  progress: 0,
  decide: false
};
export default function FileUpload(props) {
  const classes = useStyles();
  const firebase = useFirebase();
  const [filenames, setFilenames] = useState([]);
  const [URLs, setURLs] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [decide, setDecide] = useState(false);
  const uploader = useRef(null);
  // Use ref as a "instance variable" to avoid rerenders and allow
  // functions to finish calculations so they can set their state properly
  const totalFilesRef = useRef(0); // Total files to upload
  const filesLeftRef = useRef(0); // Files left to upload

  const reset = () => {
    setFilenames(initialState.filenames);
    setURLs(initialState.URLs);
    setIsUploading(initialState.isUploading);
    setProgress(initialState.progress);
    setDecide(initialState.decide);
    totalFilesRef.current = 0;
    filesLeftRef.current = 0;
    props.setFileChosen(false);
  };

  const saveFiles = () => {
    filenames.forEach(file => {
      firebase.uploadImage(file);
    });
    reset();
  };

  const deleteFiles = () => {
    filenames.forEach(file => {
      firebase.deleteImage(file);
    });
    reset();
  };

  const handleUploadStart = () => {
    setIsUploading(true);
  };

  const handleProgress = progress => {
    const p =
      totalFilesRef.current === 1
        ? progress
        : 100 -
          Math.floor(100 * (filesLeftRef.current / totalFilesRef.current));
    setProgress(p);
  };

  const handleUploadError = error => {
    setIsUploading(false);
    console.error(error);
  };
  const handleUploadSuccess = async filename => {
    const filesLeft = filesLeftRef.current - 1;
    props.setFileChosen(filesLeft === 0); // used to handle stepper
    setFilenames(filenames =>
      filenames.includes(filename) ? filenames : [...filenames, filename]
    );
    setDecide(filesLeft === 0);
    setIsUploading(filesLeft !== 0);
    filesLeftRef.current = filesLeft;
  };

  const handleChange = event => {
    const files = event.target.files;
    totalFilesRef.current = files.length;
    filesLeftRef.current = files.length;

    Array.from(files).forEach(file => {
      // used to exif rotate images
      readFile(file)
        .then(createImage)
        .then(rotate.bind(undefined, file.type))
        .then(blob => {
          const url = URL.createObjectURL(blob);
          setURLs(urls => (urls.includes(url) ? urls : [...urls, url])); // to preview files

          blob.name = file.name;
          uploader.current.startUpload(blob);
        });
    });
  };

  return (
    <Container className={classes.root}>
      <div>
        <label htmlFor="uploader">
          <Button
            className={classes.uploadButton}
            component="label"
            variant="contained"
          >
            Välj bilder
            <FileUploader
              accept="image/*"
              id="uploader"
              name="image-uploader-multiple"
              storageRef={firebase.storage().ref("images")}
              onChange={handleChange}
              onUploadStart={handleUploadStart}
              onUploadError={handleUploadError}
              onUploadSuccess={handleUploadSuccess}
              onProgress={handleProgress}
              ref={uploader}
              multiple
              hidden
            />
            <Icon className={classes.rightIcon}>attach_file</Icon>
          </Button>
        </label>
      </div>
      <div>
        <Button
          variant="contained"
          onClick={deleteFiles}
          disabled={!decide}
          className={classes.saveButton}
        >
          Upload
          <CloudUploadIcon className={classes.rightIcon} />
        </Button>
        <Button
          variant="contained"
          onClick={saveFiles}
          disabled={!decide}
          className={classes.deleteButton}
        >
          Delete
          <DeleteIcon className={classes.rightIcon} />
        </Button>
      </div>
      <Container>
        {isUploading && (
          <LinearProgress variant="determinate" value={progress} />
        )}
        <Divider hidden />
        {URLs && (
          <>
            <Divider hidden />
            <Grid container style={{ flexGrow: 1 }} spacing={2}>
              <Grid item xs={12}>
                <Grid container justify="center" spacing={2}>
                  {URLs.map((url, i) => (
                    <Grid item key={i}>
                      <img src={url} style={{ maxHeight: "20vh" }} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </Container>
  );
}
