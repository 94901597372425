import React, { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useFirebase } from "fbase";
import "./SlideShow.css";

const defaultProps = {
  showIndex: false,
  showBullets: false,
  infinite: true,
  showThumbnails: false,
  showFullscreenButton: true,
  showGalleryFullscreenButton: true,
  useBrowserFullscreen: true,
  showPlayButton: false,
  showGalleryPlayButton: true,
  showNav: false,
  isRTL: false,
  slideDuration: 1000,
  slideInterval: 3000,
  slideOnThumbnailOver: false,
  thumbnailPosition: "bottom",
  showVideo: {}
};

export default function SlideShow() {
  const [images, setImages] = useState([]);
  const firebase = useFirebase();

  useEffect(() => {
    getImages();
  }, []);

  const getImages = async () => {
    const urls = await firebase.getImages();
    setImages(urls.map(url => ({ original: url, thumbnail: url })));
  };

  return (
    <ImageGallery
      items={images}
      lazyLoad={false}
      infinite={defaultProps.infinite}
      showBullets={defaultProps.showBullets}
      showFullscreenButton={
        defaultProps.showFullscreenButton && defaultProps.showGalleryFullscreenButton
      }
      autoPlay={true}
      useBrowserFullscreen={defaultProps.useBrowserFullscreen}
      showPlayButton={defaultProps.showPlayButton && defaultProps.showGalleryPlayButton}
      showThumbnails={defaultProps.showThumbnails}
      showIndex={defaultProps.showIndex}
      showNav={defaultProps.showNav}
      isRTL={defaultProps.isRTL}
      thumbnailPosition={defaultProps.thumbnailPosition}
      slideDuration={parseInt(defaultProps.slideDuration)}
      slideInterval={parseInt(defaultProps.slideInterval)}
      slideOnThumbnailOver={defaultProps.slideOnThumbnailOver}
    />
  );
}
