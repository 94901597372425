import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Avatar,
  Button,
  TextField,
  Typography,
  makeStyles,
  Container
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useFirebase } from "fbase";
import * as ROUTES from "routes";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.main
  },
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.secondary.dark
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  textField: {
    background: "#fff",
    padding: 0,
    color: theme.palette.primary.contrastText
  },
  submit: {
    background: theme.palette.secondary.dark,
    "&:hover": {
      background: theme.palette.secondary.main
    },
    color: theme.palette.secondary.contrastText,
    margin: theme.spacing(3, 0, 2),
    padding: "10px 0px"
  },
  signInLabel: {
    color: theme.palette.primary.contrastText
  }
}));

const initState = {
  email: "",
  password: "",
  error: null
};

function LoginFormBase(props) {
  const classes = useStyles();
  const firebase = useFirebase();
  const [email, setEmail] = useState(initState.email);
  const [password, setPassword] = useState(initState.password);
  const [error, setError] = useState(initState.error);

  const onSubmit = e => {
    const pyretMail = email + "@pyret.com";
    firebase
      .signIn(pyretMail, password)
      .then(authUser => {
        props.history.push(ROUTES.UPLOAD);
      })
      .catch(error => {
        setError(error);
      });
    reset();

    e.preventDefault();
  };

  const reset = () => {
    setEmail(initState.email);
    setPassword(initState.password);
    setError(initState.error);
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className={classes.signInLabel}>
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Mitt namn"
            name="email"
            onChange={e => setEmail(e.target.value)}
            className={classes.textField}
          />
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Vad jag älskar"
            type="password"
            id="password"
            className={classes.textField}
            onChange={e => setPassword(e.target.value)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onSubmit}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Container>
  );
}

const LoginForm = withRouter(LoginFormBase);

export default LoginForm;
