import React from "react";
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";
import {
  makeStyles,
  Icon,
  Button,
  Typography,
  ListItemText,
  ListItemIcon
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

const headerLinksStyle = theme => ({
  list: {
    fontSize: "14px",
    margin: "0 auto",
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit"
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: "#e5e5e5"
      }
    }
  },
  listItemText: {
    padding: "0 !important"
  },
  navLink: {
    ...theme.typography.fontHeader,
    color: theme.palette.secondary.main,
    position: "relative",
    padding: "0.8rem 1.2375rem",
    fontSize: "16px",
    textTransform: "none",
    borderRadius: "3px",
    lineHeight: "25px",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: theme.palette.secondary.main,
      background: theme.palette.primary.main
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start"
      }
    }
  },
  navLinkActive: {
    borderBottom: `2px solid ${theme.palette.info.main}`
  },
  icons: {
    width: "20px",
    height: "20px",
    marginRight: "3px"
  },
  marginRight5: {
    marginRight: "5px"
  }
});

const useStyles = makeStyles(theme => headerLinksStyle(theme));

function HeaderLinks(props) {
  const classes = useStyles();
  const path = props.location.pathname;

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          className={classNames({
            [classes.navLink]: true,
            [classes.navLinkActive]: path === "/"
          })}
        >
          <Link to="/">
            <Icon fontSize="large" color="secondary">
              home
            </Icon>
          </Link>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <ListItemText className={classes.listItemText}>
          <Button>
            <Link
              to="/slides"
              color="transparent"
              className={classNames({
                [classes.navLink]: true,
                [classes.navLinkActive]: path === "/slides"
              })}
            >
              Min bildshow
            </Link>
          </Button>
        </ListItemText>
      </ListItem>
      <ListItem className={classes.listItem}>
        <ListItemText className={classes.listItemText}>
          <Button>
            <Link
              to="/album"
              color="transparent"
              className={classNames({
                [classes.navLink]: true,
                [classes.navLinkActive]: path === "/album"
              })}
            >
              Mitt album
            </Link>
          </Button>
        </ListItemText>
      </ListItem>
      <ListItem className={classes.listItem}>
        <ListItemText className={classes.listItemText}>
          <Button>
            <Link
              to="/upload"
              color="transparent"
              className={classNames({
                [classes.navLink]: true,
                [classes.navLinkActive]: path === "/upload"
              })}
            >
              Ladda upp bild
            </Link>
          </Button>
        </ListItemText>
      </ListItem>
    </List>
  );
}

export default withRouter(HeaderLinks);
