import React, { useState, useEffect } from "react";
import {
  GridList,
  GridListTile,
  makeStyles,
  CircularProgress,
  Typography
} from "@material-ui/core";
import { useFirebase } from "fbase";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const useStyles = makeStyles(theme => ({
  root: {
    background: "transparent",
    [theme.breakpoints.up("md")]: {
      marginTop: "30px"
    },
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    background: theme.palette.primary.dark,
    padding: "20px 0px"
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
  gridList: {
    margin: theme.spacing(2)
  },
  progress: {
    margin: theme.spacing(2)
  }
}));
export default function ImageFeed() {
  const classes = useStyles();
  const [images, setImages] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const firebase = useFirebase();

  useEffect(() => {
    getImages();
  }, []);

  const getImages = async () => {
    setImages(await firebase.getImages());
  };

  const toggleLightbox = photoIndex => {
    setPhotoIndex(photoIndex);
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isOpen ? (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      ) : (
        <div className={classes.root}>
          {!images.length ? (
            <CircularProgress className={classes.progress} color="secondary" />
          ) : (
            <GridList
              cellHeight={window.innerWidth > 956 ? 300 : "auto"}
              className={classes.gridList}
              spacing={30}
              cols={4}
            >
              {images.map((url, i) => (
                <GridListTile key={i} cols={images.cols || 1}>
                  <img
                    src={url}
                    className={classes.image}
                    onClick={() => toggleLightbox(i)}
                  />
                </GridListTile>
              ))}
            </GridList>
          )}
        </div>
      )}
    </>
  );
}
/**
        <Grid container xs={12}>
          <Grid container >
            {images.map((url, i) => (
              <Grid item key={i}>
                <img src={url} onClick={() => toggleLightbox(i)} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
       */
