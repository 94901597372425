import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import { shuffleArray } from "utils";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};
/*
const config = {
  apiKey: "AIzaSyC7wpoQIPuX6pgX0mplm7GZLCgllmeFQyQ",
  authDomain: "pyret-4fd56.firebaseapp.com",
  databaseURL: "https://pyret-4fd56.firebaseio.com",
  projectId: "pyret-4fd56",
  storageBucket: "pyret-4fd56.appspot.com",
  messagingSenderId: "472732872493",
  appId: "1:472732872493:web:90c6e2f48835b7fe"
};*/
firebase.initializeApp(config);

class Firebase {
  constructor() {
    this._auth = firebase.auth();
    this._db = firebase.firestore();
    this._storage = firebase.storage();
    this._imageURLs = this._getImageURLs();
    this._user = null;
    this.doSignOut();
  }

  auth = () => this._auth;

  db = () => this._db;

  storage = () => this._storage;

  user = () => this._user;

  uploadImage = async filename => {
    /* Add image to storage */
    const ref = this.storage().ref(`images/${filename}`);
    const url = await ref.getDownloadURL();
    await this._db
      .collection("images")
      .doc(filename)
      .set({
        url: url,
        date: firebase.firestore.Timestamp.fromDate(new Date())
      });
    return url;
  };

  deleteImage = async filename => {
    /* Delete image from storage*/
    const ref = this._storage.ref(`images/${filename}`);
    //const url = await ref.getDownloadURL();
    ref
      .delete()
      .then(function() {
        console.log("Image deleted successfully from storage.");
      })
      .catch(function(error) {
        throw new Error("Error deleting image from storage", error);
      });
    /* Delete image link from firestore*/
    /*const fsImages = await this._db.collection("images");
    fsImages
      .where("url", "==", url)
      .get()
      .then(snapshot => {
        console.log("Image deleted successfully from firestore");
        snapshot.forEach(doc => doc.ref.delete());
      })
      .catch(function(error) {
        throw new Error("Error deleting image from firestore", error);
      });*/
  };

  addDentastix = () => {
    const doc = this._db.collection("misc").doc("dentastix");
    doc.update({
      count: firebase.firestore.FieldValue.increment(1)
    });
  };

  getDentastix = async () => {
    const doc = await this._db
      .collection("misc")
      .doc("dentastix")
      .get();
    if (doc.exists) {
      return doc.data().count;
    }
    return 1337;
  };

  addLike = () => {
    const doc = this._db.collection("misc").doc("likes");
    doc.update({
      count: firebase.firestore.FieldValue.increment(1)
    });
  };

  getLikes = async () => {
    const doc = await this._db
      .collection("misc")
      .doc("likes")
      .get();
    if (doc.exists) {
      return doc.data().count;
    }
    return 1337;
  };

  /** 
  deleteImageWithURL = async url => {
    /// Delete image from storages
    const ref = this._storage.refFromURL(url);
    ref
      .delete()
      .then(function() {
        console.log("Image deleted successfully from storage.");
      })
      .catch(function(error) {
        throw new Error("Error deleting image", error);
      });
    // Delete image link from firestore
    const fsImages = await this._db.collection("images");
    fsImages
      .where("url", "==", url)
      .get()
      .then(snapshot => snapshot.forEach(doc => doc.ref.delete()));
};*/

  _getImageURLs = async () => {
    const snapshot = await this._db.collection("images").get();
    const urls = snapshot.docs.map(doc => doc.data().url); // holds urls to images in storage
    shuffleArray(urls);
    return urls;
  };

  getImages = async () => {
    const urls = await this._imageURLs;
    return urls;
  };

  signIn = (email, password) =>
    this._auth
      .signInWithEmailAndPassword(email, password)
      .then(authUser => (this._user = authUser));

  doCreateUserWithEmailAndPassword = (email, password) =>
    this._auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this._auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => {
    this._auth.signOut();
    this._user = null;
  };

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);
}
export default Firebase;
