import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  AppBar,
  Button,
  Toolbar,
  makeStyles,
  Hidden,
  IconButton,
  useTheme
} from "@material-ui/core";
import Menu from "@material-ui/icons/Menu";

import HeaderLinks from "./HeaderLinks";
import Sidebar from "components/Sidebar";
import routes from "routes";
import sidebarImage from "assets/img/stars_header.jpg";
import headerStyle from "assets/jss/components/headerStyle";
import logo from "assets/icons/bulldog.svg";

const useStyles = makeStyles(theme => ({
  ...headerStyle(theme),
  toolbar: {
    flexWrap: "wrap"
  },
  appbarMobile: {
    alignItems: "flex-start"
  },
  toolbarTitle: {
    flexGrow: 1
  },
  background: {
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center"
  },
  sidebarWrapper: {
    position: "relative",
    height: "calc(100vh - 75px)",
    overflow: "hidden",
    width: "260px",
    zIndex: "4",
    overflowScrolling: "touch"
  },
  colorOnScroll: {
    background: theme.palette.primary.dark
  }
}));
export default function Header(props) {
  const theme = useTheme();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes.colorOnScroll);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes.colorOnScroll);
    }
  };

  useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return () => {
      if (props.changeColorOnScroll)
        window.removeEventListener("scroll", headerColorChange);
    };
  }, [props.changeColorOnScroll]);

  const { color, style, image, rightLinks, leftLinks, brand, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  const brandComponent = <Button className={classes.title}>{brand}</Button>;
  return (
    <AppBar color="default" className={appBarClasses}>
      <Toolbar className={classes.container} style={style}>
        <Hidden mdDown>
          <HeaderLinks />
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
        <Hidden mdUp implementation="js">
          <Sidebar
            routes={routes}
            logoText={"Pyret"}
            logo={logo}
            image={sidebarImage}
            open={mobileOpen}
            color={theme.palette.primary.main}
            handleDrawerToggle={handleDrawerToggle}
          />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};
